.hero__two-container{
    display: flex;
    place-items: center;
    width: 100%;
    height: 100vh;
    position: relative;
    left: 0;
    text-align: center;
    color: var(--color-white);
    background: url("../../assets/images/home-office-336377_1920.jpg") center center/cover no-repeat;
    box-shadow: -1px 5px 10px 1px rgb(0 0 0 / 20%);
    min-height: 500px;
    background-attachment: fixed;
    background-blend-mode: overlay;
    background-color: var(--global-color-primary);
}

.hero__two-container h1{
    font-weight: 300;
    font-size: 3.750em;
    /*color: #2c3e50;*/
    color: #ffffff;
    line-height: 70px;
    text-align: left;
    margin-bottom: 25px;

}



.hero__two-container h3{
    font-size: 1.250em;
    font-weight: normal;
    /*color: #2c3e50;*/
    color: #ffffff;
    /*animation: typing 2s steps(30), blink .5s step-end infinite alternate;*/

    /*overflow: hidden;*/
    /*border-right: .15em solid orange;*/
}
/*@keyframes typing {*/
/*    from {*/
/*        width: 0;*/
/*        white-space: nowrap;*/
/*    }*/
/*    to {*/
/*        width: 100%;*/
/*        white-space: unset;*/
/*        flex-wrap: wrap;*/
/*    }*/
/*}*/

/*@keyframes blink {*/
/*    50% {*/
/*        border-color: transparent*/
/*    }*/
/*}*/



.hero__two-container h4{
    font-size: 1.250em;
    font-weight: normal;
    /*color: #808b96;*/
    color: #ffffff;
    margin: 10px 0 15px;
}

.container__left, .container__right{
    width: 50%;
}

.container__left{
    height: 100vh;
    background-color: var(--global-color-primary);
    display: grid;
    align-items: center;
    text-align: left;
    min-height: 500px;
    position: relative;
}

.container__left .content__text{
    width: 467px;
    height: 100%;
    margin: auto;
    justify-content: center;
    display: flex;
    flex-flow: column;
}

.container__left .content__text button{
    width: fit-content;
    font-size: 20px;
    color: var(--global-color-primary);
    background: #ffffff;
    padding: 1rem 2rem;
    cursor: pointer;
    border: 2px solid #ffffff;
    margin-top: 10px;
    transition: all 0.5s linear;
    font-weight: 600;
}

.container__left .content__text button:hover{
    background: transparent;
    color: white;
}

.hero__wave_section{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.hero__wave_section svg{
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 200px;
}

.hero__wave_section svg path{
    stroke: #1e409adb;
    fill: #1e409adb;
}


/* ================ MEDIA QUERIES (MEDIUM DEVICES) ====================== */
@media screen and (max-width: 1024px){
    .container{
        width: var(--container-width-md);
    }

    .container__left{
        width: 70%;
    }
    .container__right{
        width: 30%;
    }
}

@media screen and (min-width: 768px) and (max-width: 900px){
    .hero__two-container{
        height: 70vh;
        padding: 0 3rem;
    }
    .container__left{
        height: 100%;
        width: 90%;
    }
}
/* ================ MEDIA QUERIES (SMALL DEVICES) ====================== */
@media screen and (max-width: 767px){
    .container{
        width: var(--container-width-sm);
    }

    .container__left{
        width: 100%;
        opacity: 0.94;
    }
    .container__left .content__text{
        width: 100%;
        padding: 0 1rem;
        text-align: center;
    }
    .container__left .content__text h1{
        text-align: center;
    }
    .container__left .content__text button{
        margin: 30px auto;
    }
    .container__right{
        display: none;
    }

    .hero__two-container{
        padding: 0 1rem;
    }
}

@media screen and (max-width: 400px){
    .hero__two-container h1{
        font-size: 3rem;
        padding-top: 3rem;
    }

    .hero__two-container{
        line-height: 1.2;
    }
}