.contact__section-container{
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
    margin: 1rem auto;
}

#contact__section{
    background: var(--global-color-primary);
    padding-bottom: 3rem;
}

#contact__section > h2{
    text-align: center;
    padding-top: 3rem;
    font-size: 3rem;
    color: #fff;
    padding-bottom: 3rem;
}

.contact__section-options{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
.contact__section-option{
    background: var(--global-color-primary-dark);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    color: #ffffff;
    transition: all 1s ease;
}
.contact__section-option > h5{
    overflow-wrap: break-word;
}
.contact__section-option:hover{
    background: transparent;
    border-color: var(--color-text-third);
}
.contact__section-option-icon{
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}
.contact__section-option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
    color: var(--color-text-third);
    font-weight: 600;
}

/* ============= FORM ================== */
form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea{
    width: 100%;
    padding: 1.5rem;
    border-radius: 1.5rem;
    background: transparent;
    border: 2px solid var(--global-color-accent);
    resize: none;
    color: var(--color-white);
    font-size: 1rem;
}

.contact-btn-overlay{
    padding: 1rem 2rem;
    /*border: 1px solid #ffffff;*/
    background: var(--global-color-primary-dark);
    font-size: 1.3rem;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.5s ease;
    margin: 0 auto;
}

.contact-btn-overlay:hover{
    background: transparent;
    border:2px solid var(--global-color-accent);
    /*color: var(--global-color-accent);*/
}


/* ================ MEDIA QUERIES (MEDIUM DEVICES) ====================== */
@media screen and (max-width: 1024px){
    .contact__section-container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}


/* ================ MEDIA QUERIES (SMALL DEVICES) ====================== */
@media screen and (max-width: 768px){
    .contact__section-container{
        grid-template-columns: 1fr;
        width: var(--container-width-sm);
    }
}