#top-bar{
    width: 100%;
    background: var(--global-color-primary);
    position: fixed;
    top: 100px;
    z-index: 2;
}

.top-bar__container{
    max-width: 1600px;
    color: #ffffff;
    padding: 0.5rem 2rem;
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr;
}

.top-bar__container a{
    color: #ffffff;
}
.top-bar__container a:hover{
    color: var(--color-text-third);
}

.top-bar__left, .top-bar__right{
    display: flex;
    gap: 2rem;
    font-size: 1.25rem;
    /*grid-template-columns: 1fr 1fr;*/
}

.top-bar__left .top-bar__email, .top-bar__left .top-bar__phone{
    gap: 0.5rem;
    display: flex;
}

.top-bar__right{
    align-content: flex-end;
    text-align: right;
    justify-content: end;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ====================== */
@media screen and (max-width: 1024px){
    .top-bar__container{
        grid-template-columns: 4fr 1fr;
    }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ====================== */
@media screen and (max-width: 767px){
    .top-bar__container{
        grid-template-columns: 1fr;
        gap: 0.5rem;
    }

    .top-bar__left{
        flex-direction: column;
        gap: 1rem;
    }
}