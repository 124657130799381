body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  /*scrollbar-base-color: #1e409a;*/
  /*-ms-scrollbar-face-color: #1e409a;*/
  /*scrollbar-face-color: #1e409a;*/
}

html::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

html::-webkit-scrollbar-thumb {
  background: var(--global-color-primary);
  /*background: linear-gradient(180deg, #ffffff, #1e409a 50%);*/
  /*border-radius: 16px;*/
  /*box-shadow: inset 2px 2px 2px hsl(0deg 0% 100% / 25%), inset -2px -2px 2px rgb(0 0 0 / 25%);*/
}

html::-webkit-scrollbar-track {
  /*background: linear-gradient(to bottom, #ffffff, #1e409a);*/
  background: transparent;
}

*{
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root{
  --global-color-primary: #15546c;
  --global-color-prymary-tranparency:#15546c;
  --global-color-secondary: #fff;
  --global-color-primary-dark: #0f3b4c;
  --global-color-accent: #fff;
  --global-color-heading: #3687b3;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;


  --color-prime: #1e409a;
  --color-text-primary: #2c3e50;
  --color-text-secondary: #808b96;
  --color-text-third: #ff974d;
  --color-primary: #314fa0;
  --color-bg: #ffffff;
  --color-white: #ffffff;

  --transition: all 400ms ease;


}

html{
  scroll-behavior: smooth;
}

/* ============= GENERAL DESIGN ============ */

.container{
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1, h2, h3, h4, h5, h6{
  font-weight: 500;
}

h1{
  font-size: 2.5rem;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ====================== */
@media screen and (max-width: 1200px){
  .container{
    width: var(--container-width-md);
  }

  /*section{*/
  /*  margin-top: 6rem;*/
  /*}*/
}


/* ================ MEDIA QUERIES (SMALL DEVICES) ====================== */
@media screen and (max-width: 768px){
  .container{
    width: var(--container-width-sm);
  }

  /*section > h2{*/
  /*  margin-bottom: 2rem;*/
  /*}*/
}