#aboutPage{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    background: var(--global-color-primary-dark);
}

#aboutPage h1{
    margin: auto;
    color: #ffffff;
}