.roadmap__container{
    background: var(--global-color-primary-dark);
    padding-bottom: 5rem;
}

.roadmap__container > h2{
    margin-top: 5rem;
    text-align: center;
    font-size: 3rem;
     color: #1e409a;
}


.roadmap__content{
    max-width: 1600px;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.roadmap__items{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
    border-bottom: 1px solid #ffffff;
    padding: 5rem 2rem;
}

.roadmap__item{
    background: var(--global-color-primary);
    border: 1px solid white;
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 70% 50% 40% 31%;
}

.roadmap__item .roadmap__item-icon{
    color: white;
    font-size: 5rem;
    margin: 0 auto 1rem;
    border: 1px solid;
    padding: 1rem;
    border-radius: 10%;
}

.roadmap__icon_container{
    display: flex;
    position: relative;
    margin-bottom: 2rem;
}

.roadmap__item-number{
    font-size: 1rem;
    color: #fff;
    border: 1px solid #fff;
    padding: 10px 15px;
    border-radius: 50%;
    position: absolute;
    top: 60%;
    left: 60%;
    background: #3d8fd8;
}

.roadmap__item h3 {
    color: #ffffff;
    margin-bottom: 1rem;
    font-size: 1.75rem;
}

.roadmap__item p{
    color: #ffffff;
    font-size: 1.25rem;
    line-height: 1.2;
}

.roadmap__divider{
    position: absolute;
    left: 65%;
    top: 75px;
    width: 45%;
    height: 3px;
    background-size: 12px 1px;
    background-image: linear-gradient(90deg,#1e409a,#fff 40%,transparent 40%,transparent 100%)
}

.roadmap__divider::after{
    color: var(--global-color-accent);
    content: "";
    font-size: 30px;
    position: absolute;
    right: -18px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid var(--global-color-accent);
}

.roadmap__bottom_text{
    padding: 2rem 8rem;
}

.roadmap__bottom_text p{
    text-align: center;
    font-size: 1.5rem;
    color: #fff;
    letter-spacing: 0.1rem;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ====================== */
@media screen and (max-width: 1024px){
    .roadmap__items{
        grid-template-columns: 1fr 1fr;
    }

    .roadmap__item:nth-child(1){
        order: 1;
    }

    .roadmap__item:nth-child(2){
        order: 2;
    }

    .roadmap__item:nth-child(2) .roadmap__divider{
        width: 3px;
        height: 45px;
        background-size: 1px 12px;
        background-image: linear-gradient(181deg, #ffffff, transparent);
        top: 90%;
        left: 50%;
    }

    .roadmap__item:nth-child(2) .roadmap__divider::after{
        right: -7px;
        top: 82%;
        transform: rotate(90deg);
        font-size: 30px;
        color: var(--global-color-accent);
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 15px solid var(--global-color-accent);
    }

    .roadmap__item:nth-child(3){
        order: 4;
    }

    .roadmap__item:nth-child(3) .roadmap__divider-ipad.roadmap__divider{
        left: -20px;
        width: 40%;
    }

    .roadmap__divider.hide-ipad{
        display: none;
    }

    .roadmap__item:nth-child(3) .roadmap__divider-ipad.roadmap__divider::after{
        display: none;
    }

    .roadmap__divider-ipad.roadmap__divider::before{
        content: '';
        position: absolute;
        left: -15px;
        bottom: -9px;
        transform: rotate(180deg);
        font-size: 30px;
        color: var(--global-color-accent);
        display: block;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 15px solid var(--global-color-accent);
    }
    /*.roadmap__divider:nth-child(3).roadmap__divider-ipad{*/
    /*    display: none;*/
    /*}*/

    .roadmap__item:nth-child(4){
        order: 3;
    }

    .roadmap__bottom_text p{
        font-size: 1.25rem;
    }
    .roadmap__divider.roadmap__divider-ipad{
        left: 0;
        width: 25%;
    }
    .roadmap__divider.roadmap__divider-ipad::after{
        color: #ffffff;
    }
    .roadmap__divider.roadmap__divider-ipad::after{
        color: #ffffff;
    }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ====================== */
@media screen and (max-width: 767px){
    .roadmap__bottom_text{
        padding: 2rem 2rem;
    }
    .roadmap__item:nth-child(1){
        order: 1;
    }

    .roadmap__item:nth-child(2){
        order: 2;
    }

    .roadmap__item:nth-child(3){
        order: 3;
    }

    .roadmap__divider.hide-ipad{
        display: block;
    }

    .roadmap__item:nth-child(4){
        order: 4;
    }

}

@media screen and (max-width: 600px){
    .roadmap__items{
        grid-template-columns: 1fr;
    }

    .roadmap__divider {
        /*position: absolute;*/
        width: 3px;
        height: 45px;
        background-size: 1px 12px;
        background-image: linear-gradient(181deg, #ffffff, transparent);
        top: 90%;
        left: 50%;
    }

    .roadmap__divider::after{
        right: -7.5px;
        top: 90%;
        transform: rotate(90deg);
        font-size: 30px;
        color: var(--global-color-accent);
    }

    .roadmap__divider.roadmap__divider-ipad{
        display: none;
    }
}