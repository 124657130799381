#testimonial-slider{
    /*max-width: 1600px;*/
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
    padding-bottom: 3rem;
    overflow: hidden;
}

#testimonial-slider h2{
    color: var(--global-color-heading);
    padding: 2rem 0;
    text-align: center;
    font-size: 3rem;
}

.testimonial__container{
    width: 40%;
    padding-bottom: 3rem;
    max-width: 1600px;
}

.testimonial-slide{
    width: 100%;
    background: var(--global-color-primary);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
    color: var(--global-color-accent);
    border: 2px solid #ffffff;
}

.client-avatar{
    width: 5rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--global-color-accent);
}

.client-avatar img{
    max-width: 100%;
    background: #ebc94c;
}

.client-data h3{
    font-size: 2rem;
}

.client-data h4{
    padding-bottom: 2rem;
}

.client-review p{
    font-size: 1.2rem;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ====================== */
@media screen and (max-width: 1024px){
    .testimonial__container{
        width: 60%;
    }
}


/* ================ MEDIA QUERIES (SMALL DEVICES) ====================== */
@media screen and (max-width: 767px){
    .testimonial__container{
        width: var(--container-width-sm);
        overflow: hidden;
    }
    .client__review{
        width: var(--container-width-sm);
    }
}